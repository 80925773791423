// @ts-ignore
import authorImage from "../images/dm-author-image 550x550x2.webp";

interface Authors {
  [key: string]: { description: string; image: string; title: string };
}

const AUTHORS: Authors = {
  dXNlcjo2OTE3: {
    description:
      "Joanna Carr is a Licensed Practical Nurse (LPN) with almost two decades of clinical experience in different-sized medical environments (orthopedic practices, general hospitals). Her primary work responsibilities include assessing patients’ medical history, providing basic care, and assisting in treatment follow-ups. Joanna’s passion for expanding her knowledge led her on a path of medical blogging, which she sees as a way to stay up to date with important industry shifts and to support a wider medical community.",
    image: authorImage,
    title: "Licensed Practical Nurse (LPN)",
  },
};

export default AUTHORS;
