import {
	Box,
	Center,
	Container,
	HStack,
	Image,
	SimpleGrid,
	Stack,
	Text,
	VStack,
	useMediaQuery,
} from '@chakra-ui/react';

import AUTHORS from '../constants/authors';
import { BLOG_SORT_ITEMS } from '../constants/blogConstants';
import BlogFilter from '../components/BlogFilter';
import BlogHeroIntro from '../components/blog-hero-intro';
import Layout from '../components/Base/Layout';
import MobileBlogFilter from '../components/BlogFilter/MobileBlogFilter';
import Pagination from '../components/Shop/Pagination';
import React from 'react';
import { SEO } from '../components/Base/Seo';
import SortBy from '../components/SortBy';
import { graphql } from 'gatsby';
import { useBlogs } from '../hooks/blogHooks';

const BlogAuthor = ({ data, location, pageContext }: any) => {
	const {
		allWpPost: { nodes },
	} = data;
	const posts = data.allWpPost.nodes;

	const {
		numberOfPages,
		currentPage,
		prevPageHandler,
		nextPageHandler,
		goToPage,
		onSortSelectionChange,
		sortBy,
		filteredItems,
		categories,
		selectedCategories,
		setSelectedCategories,
	} = useBlogs({
		items: posts,
		location: location,
	});

	const authorDetails = AUTHORS[pageContext.authorId];

	const categoryCounts: { [slug: string]: number } = {};
	posts.forEach((post: any) => {
		post.categories.nodes.forEach((category: any) => {
			const slug = category.slug;
			// Use category name as key for categoryCounts
			const categoryName = category.name.toLowerCase(); // Convert to lowercase for consistency
			categoryCounts[categoryName] = (categoryCounts[categoryName] || 0) + 1;
		});
	});

	const [isMobile] = useMediaQuery('(max-width: 768px)');

	const onClearFilters = () => {
		setSelectedCategories([]);
	};

	return (
		<Layout>
			<>
				<Box
					h={{ base: '100%', md: '640px' }}
					bgGradient='linear(to-b, #F7E8DB, #FFF)'
					pt={5}>
					<Container
						maxW={{ base: '3xl', md: '1280px' }}
						px={0}>
						<Stack
							flexDir={{ base: 'column', lg: 'row' }}
							py={10}
							maxW={'100%'}
							mx='auto'
							px={{ base: '12px' }}
							gap={'5rem'}>
							<VStack
								justifyContent='center'
								pl={{ base: 0, lg: 10 }}
								alignItems='flex-start'
								mx='auto'
								maxW='650px'>
								<Box
									bg='#B97230'
									width='24px'
									height='2px'
								/>
								<Text
									pt={{ base: '12px', lg: 8 }}
									color='#D28C49'
									fontSize='20px'
									fontWeight='700'>
									AUTHOR
								</Text>
								<Text
									as='h1'
									fontSize='36px'
									fontWeight='400'
									color='#262626'>
									{nodes[0]?.author?.node.name}, {authorDetails.title}
								</Text>
								<Text
									pb='28px'
									fontSize='16px'
									color='#262626'
									lineHeight='150%'
									fontWeight='400'>
									{authorDetails.description}
								</Text>
							</VStack>
							<Image
								w={{ base: '343px', md: '550px' }}
								h={{ base: '343px', md: '550px' }}
								borderRadius='24px'
								src={authorDetails.image}
								alt='Joanna Carr, medical writer'
							/>
						</Stack>
					</Container>
				</Box>
				<Box>
					<HStack
						px={{ base: 4, '2xl': 48 }}
						py={5}
						justifyContent='space-between'
						alignItems='center'
						borderBottom='1px solid rgba(0, 0, 0, 0.16)'>
						<Text
							color='#120B0C'
							fontSize={{ base: '24px', md: '36px' }}
							fontWeight={400}>
							Author's articles
						</Text>
						{isMobile && (
							<MobileBlogFilter
								categories={categories}
								selectedCategories={selectedCategories}
								setSelectedCategories={setSelectedCategories}
								filteredItems={filteredItems}
								categoryCounts={categoryCounts}
								items={BLOG_SORT_ITEMS}
								selectedSortItem={BLOG_SORT_ITEMS.find(
									(item) => item.value === sortBy
								)}
								onSortSelectionChange={onSortSelectionChange}
								onClearFilters={onClearFilters}
							/>
						)}

						<Stack display={{ base: 'none', lg: 'flex' }}>
							<SortBy
								fontSize='md'
								selectedItem={BLOG_SORT_ITEMS.find(
									(item) => item.value === sortBy
								)}
								items={BLOG_SORT_ITEMS}
								onItemChange={onSortSelectionChange}
							/>
						</Stack>
					</HStack>
					<>
						<Box bg={'#F9F9F9'}>
							<Stack
								w='100%'
								px={2}
								flexDir='row'
								justifyContent={{ md: 'space-between', lg: 'center' }}>
								<Box
									display={{ lg: 'flex', base: 'none' }}
									bg='white'>
									<Stack
										w='324px'
										direction='column'
										spacing={4}
										borderRight={'1px solid rgba(0, 0, 0, 0.16)'}
										borderBottom={'1px solid rgba(0, 0, 0, 0.16)'}>
										<Text
											px={2}
											pt={5}
											pb={3}
											fontWeight='400'
											fontSize='md'
											lineHeight='24px'
											color='#120B0C'>
											Categories
										</Text>
										<BlogFilter
											categories={categories}
											selectedCategories={selectedCategories}
											setSelectedCategories={setSelectedCategories}
											filteredItems={filteredItems}
											categoryCounts={categoryCounts}
										/>
									</Stack>
								</Box>

								<SimpleGrid
									pt={2}
									maxW='100%'
									bg={'#F9F9F9'}
									columns={{ base: 1, md: 2, lg: 3 }}
									gap={{ base: '18px', lg: '18px' }}>
									{filteredItems.map((item: any) => (
										<BlogHeroIntro
											authorSlug={item.author?.node?.slug}
											link={'/blog/' + item.slug}
											blogImgThumb={item.featuredImage?.node?.sourceUrl}
											category={item.categories.nodes.map((item: any) =>
												item.name.toUpperCase()
											)}
											postTitle={item.title}
											excerpt={item.seo.metaDesc}
											author={item.author?.node?.name}
											date={item.date}
										/>
									))}
								</SimpleGrid>
							</Stack>
							<Center pt='24px'>
								<Center pt='24px'>
									<Pagination
										numberOfPages={numberOfPages}
										currentPage={currentPage}
										prevPageHandler={prevPageHandler}
										nextPageHandler={nextPageHandler}
										goToPage={goToPage}
										baseUrl={`blog/author/medical-writer`}
									/>
								</Center>
							</Center>
						</Box>
					</>
				</Box>
			</>
		</Layout>
	);
};

export const query = graphql`
	query ($authorId: ID!) {
		allWpCategory {
			nodes {
				name
				count
				slug
			}
		}
		allWpPost(filter: { authorId: { eq: $authorId } }) {
			nodes {
				author {
					node {
						description
						id
						name
						slug
					}
				}
				id
				featuredImage {
					node {
						sourceUrl
					}
				}
				categories {
					nodes {
						name
						slug
						count
					}
				}
				seo {
					title
					metaDesc
				}
				slug
				title
				date(formatString: "MMMM DD, YYYY")
			}
		}
	}
`;

export const Head = () => (
	<SEO
		title='Author | DoctorMedica'
		description="Meet Joanna Carr, a Licensed Practical Nurse (LPN) with almost 20 years of clinical experience, and Doctor Medica's principal article writer."
	/>
);

export default BlogAuthor;
